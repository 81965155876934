import React, { useState, useEffect, useRef } from "react";
import { useSwipeable } from "react-swipeable";
import SliderSimple from './SliderSimple';
import SliderMultiple from './SliderMultiple';
import SliderCombined from './SliderCombined';
import { Link } from 'react-router-dom';
import bottomLogo from './images/img_bottom@2x.png';

const MainPage = () => {
  const [showLanguageMenu, setShowLanguageMenu] = useState(false);
  const defaultLanguage = localStorage.getItem('language') || navigator.language || navigator.userLanguage;
  // const initialLanguage = ['ja', 'zh-TW'].includes(defaultLanguage) ? defaultLanguage : 'en-US';
  // const [currentLanguage, setCurrentLanguage] = useState(initialLanguage);
  const [currentLanguage, setCurrentLanguage] = useState('en-US');
  const [systemLanguageData, setSystemLanguageData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showInfoDiv, setShowInfoDiv] = useState(true);

  const [isRefreshing, setIsRefreshing] = useState(false);
  const startY = useRef(0);
  const contentRef = useRef(null);

  const [pullDistance, setPullDistance] = useState(0);
  const pullThreshold = 200; // The distance needed to pull to trigger a refresh
  const [isAtTop, setIsAtTop] = useState(true);

  const [isInfoExpanded, setIsInfoExpanded] = useState(() => {
    const storedValue = localStorage.getItem('isInfoExpanded');
    return storedValue !== null ? JSON.parse(storedValue) : true;
  });
  useEffect(() => {
    localStorage.setItem('isInfoExpanded', JSON.stringify(isInfoExpanded));
  }, [isInfoExpanded]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const systemLanguage = localStorage.getItem('language');
        var response = await fetch("./data/system_" + systemLanguage + ".json");
        let data;
        try {
          data = await response.json();
          console.log("成功獲取到 JSON 格式的數據:", data);
        } catch (error) {
          console.error("獲取 JSON 格式數據失敗:", error);
        }
        setSystemLanguageData(data);
        setTimeout(() => {
          setIsLoading(false);
          // setIsInfoExpanded(true);
          const infoDivVisibility = localStorage.getItem('isInfoExpanded');
          if (infoDivVisibility !== null) {
            setIsInfoExpanded(JSON.parse(infoDivVisibility));

          }
          // setIsInfoExpanded(false);
        }, 1000);

      } catch (error) {
        console.error("Error fetching data:", error);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      }
    };

    fetchData();
    // localStorage.setItem('showInfoDiv', 'true');

    // Add orientation change event listener
    const handleOrientationChange = () => {
      window.location.reload();
    };
    window.addEventListener('orientationchange', handleOrientationChange);

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, [currentLanguage]);

  const handleRefresh = () => {
    setIsRefreshing(true);
    setPullDistance(0);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const handlers = useSwipeable({
    onSwipedDown: (eventData) => {
      if (isAtTop && pullDistance >= pullThreshold) {
        handleRefresh();
      }
    },
    onSwiping: (eventData) => {
      if (isAtTop && eventData.deltaY > 0) {
        setPullDistance(Math.min(eventData.deltaY, pullThreshold));
      }
    },
    onSwipedUp: () => setPullDistance(0),
    onTouchEndOrOnMouseUp: () => {
      if (pullDistance < pullThreshold) {
        setPullDistance(0);
      }
    },
    preventDefaultTouchmoveEvent: false,
    trackMouse: true
  });

  const languages = [
    { code: 'en-US', name: 'English' },
    // { code: 'ja', name: 'Japanese' },
    // { code: 'zh-TW', name: 'Chinese' }
  ];

  const handleLanguageChange = (langCode) => {
    setCurrentLanguage(langCode);
    localStorage.setItem('language', langCode);
    setShowLanguageMenu(false);
    window.location.reload();
  };

  // const handleCloseInfoDiv = () => {
  //   setShowInfoDiv(false);
  //   localStorage.setItem('showInfoDiv', 'false');
  // };
  const handleScroll = () => {
    if (contentRef.current) {
      setIsAtTop(contentRef.current.scrollTop === 0);
    }
  };
  // const [isInfoExpanded, setIsInfoExpanded] = useState(true);

  const handleToggleInfo = () => {
    setIsInfoExpanded(prevState => !prevState);
  };
  // const handleToggleInfo = () => {
  //   setIsInfoExpanded(!isInfoExpanded);
  //   // setShowInfoDiv(isInfoExpanded);
  //   if (isInfoExpanded) {
  //     localStorage.setItem('isInfoExpanded', 'true');
  //   } else {
  //     localStorage.setItem('isInfoExpanded', 'false');
  //   }
  // };
  return (
    <div
      ref={contentRef}
      {...handlers}
      onScroll={handleScroll}
      style={{
        overflowY: 'auto',
        height: 'calc(100vh - 80px)',
        paddingBottom: '80px',
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: `${pullDistance}px`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          transition: 'height 0.2s',
          overflow: 'hidden',
          color: 'white',
          fontWeight: 'bold',
          textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
          zIndex: 1000,
        }}
      >
        {/* <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: `${pullDistance}px`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          transition: 'height 0.2s',
          overflow: 'hidden',
          color: 'white', // Changed text color to white
          fontWeight: 'bold', // Optional: make the text bold for better visibility
          textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
        }}
      > */}
        {pullDistance >= pullThreshold ? systemLanguageData?.releaseToRefresh || 'Relese to refresh' : systemLanguageData?.pullDownToRefresh || 'Pull down to refresh'}
      </div>

      {isRefreshing && (
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '60px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          zIndex: 1000,
        }}>
          <div style={{
            width: '30px',
            height: '30px',
            border: '3px solid #fff',
            borderTop: '3px solid #000',
            borderRadius: '50%',
            animation: 'spin 1s linear infinite',
          }}></div>
        </div>
      )}

      <div style={{ marginTop: '14px', marginBottom: '40px' }}>
        <h2 style={{ marginLeft: '22px', width: '94%', margin: '0 auto', marginBottom: '12px', color: '#fff' }}>Featured</h2>
        <SliderSimple />
      </div>

      <div style={{ marginBottom: '-10px' }}>
        <h2 style={{ marginLeft: '22px', width: '94%', margin: '0 auto', marginBottom: '12px', color: '#fff', display: 'flex', alignItems: 'flex-end' }}>
          Gaming Case Compatible
          <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'flex-end', gap: '10px' }}>
            <Link to={`/allGames`} style={{ color: 'white', fontSize: '12px', marginBottom: '2px' }}>
              SHOW ALL
            </Link>
          </div>
        </h2>
        <SliderMultiple />
      </div>

      <div style={{ marginTop: '0px', marginBottom: '30px' }}>
        <h2 style={{ marginLeft: '22px', width: '94%', margin: '0 auto', marginBottom: '12px', color: '#fff', display: 'flex', alignItems: 'flex-end' }}>
          My Games
          <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'flex-end', gap: '10px' }}>
            <Link to={`/addMyGame`} style={{ color: 'white', fontSize: '12px', marginBottom: '2px' }}>
              ADD GAME
            </Link>
            <Link to={`/allCombined`} style={{ color: 'white', fontSize: '12px', marginBottom: '2px' }}>
              SHOW ALL
            </Link>
          </div>
        </h2>
        <SliderCombined />
      </div>

      {showInfoDiv && (

        <div style={{
          color: '#fff',
          marginTop: '14px',
          marginBottom: '60px',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          padding: '15px',
          borderRadius: '10px',
          width: '90%',
          maxWidth: '400px',
          marginLeft: 'auto',
          marginRight: 'auto',
          position: 'relative',
        }}>
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '10px'
              }}>
                <p style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                  margin: 0,
                  flexGrow: 1,
                  paddingRight: '10px'
                }}>
                  {systemLanguageData?.addToHomeTitle || 'To add this app to your home screen:'}
                </p>
                <button
                  onClick={handleToggleInfo}
                  style={{
                    background: 'none',
                    border: 'none',
                    color: '#fff',
                    fontSize: '16px',
                    cursor: 'pointer',
                    padding: 0,
                    marginLeft: '10px',
                  }}
                >
                  {isInfoExpanded ? '▲' : '▼'}
                </button>
              </div>
              {isInfoExpanded && (
                <ol style={{ paddingLeft: '20px', margin: 0 }}>
                  <li style={{ marginBottom: '5px' }}>
                    {systemLanguageData?.addToHomeStep1 || 'Tap the share '}
                    <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18" style={{ marginLeft: '0px' }}>
                      <path d="M0 0h24v24H0V0z" fill="none" />
                      <path d="M16 5l-1.42 1.42-1.59-1.59V16h-1.98V4.83L9.42 6.42 8 5l4-4 4 4zm4 5v11c0 1.1-.9 2-2 2H6c-1.11 0-2-.9-2-2V10c0-1.11.89-2 2-2h3v2H6v11h12V10h-3V8h3c1.1 0 2 .89 2 2z" fill="white" />
                    </svg>
                    {systemLanguageData?.addToHomeStep1_2 || ' button at the bottom'}
                  </li>
                  <li>
                    {systemLanguageData?.addToHomeStep2 || 'Select Add to Home Screen '}
                    <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18" style={{ marginLeft: '0px' }}>
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-2 10h-4v4h-2v-4H7v-2h4V7h2v4h4v2z" fill="white" />
                    </svg>
                    {systemLanguageData?.addToHomeStep2_2 || ' in the pop-up window'}
                  </li>
                </ol>
              )}
            </>
          )}
        </div>
      )}

      <div style={{
        backgroundColor: '#000',
        height: '30px',
        color: '#fff',
        padding: '2px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        width: '100%',
        zIndex: 999
      }}>
        <div style={{ textAlign: 'left', color: '#fff', fontSize: '14px', marginLeft: '20px', position: 'relative' }}>
          <p onClick={() => setShowLanguageMenu(!showLanguageMenu)} style={{ cursor: 'pointer' }}>
            Region: {currentLanguage}
          </p>
          {showLanguageMenu && (
            <div style={{
              position: 'absolute',
              bottom: '100%',
              left: 0,
              backgroundColor: '#333',
              borderRadius: '5px',
              padding: '5px',
              zIndex: 1000
            }}>
              {languages.map((lang) => (
                <div
                  key={lang.code}
                  onClick={() => handleLanguageChange(lang.code)}
                  style={{ cursor: 'pointer', padding: '5px' }}
                >
                  {lang.name}
                </div>
              ))}
            </div>
          )}
        </div>

        <div style={{ textAlign: 'right', marginRight: '20px' }}>
          <img src={bottomLogo} alt="Footer Image" style={{ maxWidth: '100%', height: '30px' }} />
        </div>
      </div>

      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  );
};

export default MainPage;