import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import gameData from "./data/gameData_en-US.json";

import addFavoriteButtonImg from './images/btn_favorite@2x.png'; // 導入返回按鈕圖像
import removeButtonImg from './images/btn_favorite_focus@2x.png'; // 導入返回按鈕圖像


function SliderMultiple() {
    const [images, setImages] = useState([]);
    const [savedFavoriteGames, setSavedFavoriteGames] = useState('');
    const [gameData, setGameData] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const systemLanguage = localStorage.getItem('language');

                var response = await fetch("./data/gameData_" + systemLanguage + ".json");

                let data; // 在 try 塊外部聲明一個變量
                try {
                    data = await response.json();
                    // 在這裡處理獲取到的 JSON 格式數據
                    console.log("成功獲取到 JSON 格式的數據:", data);
                } catch (error) {
                    // JSON 格式轉換失敗，處理錯誤情況
                    response = await fetch("./data/gameData_en-US.json");
                    data = await response.json();
                    console.error("獲取 JSON 格式數據失敗:", error);
                }

                const editorsChoose = data.GamingCaseCompatible;
                const extractedImages = editorsChoose.map(item => {
                    // 檢查遊戲是否在 favoriteGames 中
                    const isFavorite = savedFavoriteGames !== null && savedFavoriteGames.split(',').includes(item.id);
                    return {
                        bigImageUrl: item.bigImageUrl,
                        imageUrl: item.imageUrl,
                        title: item.title, // 遊戲名稱
                        subTitle: item.subTitle,
                        description: item.description,
                        appStoreLink: item.appStoreLink, // App Store 鏈接
                        gameId: item.id, // 遊戲 ID
                        isFavorite: isFavorite // 遊戲是否收藏
                    };
                });
                setImages(extractedImages);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();

    }, [savedFavoriteGames]);

    useEffect(() => {
        const savedFavoriteGames = localStorage.getItem('favoriteGames');
        if (savedFavoriteGames) {
            setSavedFavoriteGames(savedFavoriteGames);
        }
    }, [savedFavoriteGames]);


    const imageWidth = 84  //106
    const vCount = 4 //3
    const wCount = 7 //5
    const orientation = window.innerHeight > window.innerWidth ? 'vertical' : 'horizontal';
    const slidesToShow = orientation === 'vertical' ? Math.min(vCount, images.length) : Math.min(wCount, images.length); //垂直3 水平5個
    const spaceWidth = slidesToShow < vCount ? 9 + 'px' : ((window.innerWidth - (slidesToShow * imageWidth)) / (slidesToShow * 2)) + 'px';// orientation === 'vertical' ? (window.innerWidth - slidesToShow * 106) / 3 + 'px' : (window.innerWidth - slidesToShow * 106) / 3 + 'px'
    // const settings = {
    //     dots: false,
    //     infinite: false, // 設為 false 以模擬頁面滾動
    //     speed: 500,
    //     swipeToSlide: true,
    //     variableWidth: true,
    //     arrows: false, // 隱藏箭頭
    //     cssEase: "cubic-bezier(0.25, 0.1, 0.25, 1)", // 使用 ease-out 緩動函數
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     vertical: false, // 保持水平滾動
    //     touchThreshold: 5, // 降低觸摸閾值，使滑動更敏感
    //     useCSS: true,
    //     useTransform: true,
    //     edgeFriction: 0.15, // 添加邊緣摩擦力
    //   };

    const settings = {
        dots: false,
        infinite: false,
        speed: 100,
        // swipeToSlide: true,
        slidesToShow: images.length < vCount ? images.length : slidesToShow,
        slidesToScroll: images.length < vCount ? images.length : slidesToShow,
        touchThreshold: 50, // 降低觸摸閾值，使滑動更敏感
        variableWidth: true,
        arrows: 0
    };

    const arrowStyles = `
    .slick-prev:before, .slick-next:before {
      color: blue; // 設置箭頭顏色
    }
  `;


    const handleImageClick = (appStoreLink, gameId) => {
        // 如果您使用 React Router，使用 history.push 導航到新頁面

        // var savedFavoriteGames = localStorage.getItem('favoriteGames');

        // if ((savedFavoriteGames == null) || (savedFavoriteGames == '')) {
        //     localStorage.setItem('favoriteGames', gameId);
        // } else {
        //     // localStorage.setItem('favoriteGames', savedFavoriteGames + "," + gameId);
        //     var favoriteGamesArray = savedFavoriteGames.split(',');

        //     // 檢查 gameId 是否已經存在於 favoriteGamesArray 中
        //     if (!favoriteGamesArray.includes(gameId)) {
        //         // 如果不存在，則將 gameId 添加到 favoriteGamesArray 中
        //         favoriteGamesArray.push(gameId);
        //         // 更新本地存儲
        //         localStorage.setItem('favoriteGames', favoriteGamesArray.join(','));

        //     }
        // }
        // savedFavoriteGames = localStorage.getItem('favoriteGames');

        // setSavedFavoriteGames(savedFavoriteGames);


        // 如果您使用原生的網頁導航，可以使用 window.location.href
        window.location.href = appStoreLink;
    };
    const addFavorite = (gameId) => {
        // 如果您使用 React Router，使用 history.push 導航到新頁面
        // history.push(appStoreLink);
        // localStorage.setItem('favoriteGames', '');

        var savedFavoriteGames = localStorage.getItem('favoriteGames');
        var favoriteGamesArray;

        if (savedFavoriteGames === null || savedFavoriteGames === undefined || savedFavoriteGames === '') {
            // localStorage 中沒有存儲 'favoriteGames' 數據
            // 進行相應的處理
            favoriteGamesArray = [gameId]; // 初始化 favoriteGamesArray
            // 更新本地存儲
            localStorage.setItem('favoriteGames', favoriteGamesArray.join(','));
        } else {
            // localStorage 中已經存儲了 'favoriteGames' 數據
            favoriteGamesArray = savedFavoriteGames.split(',');
            // 檢查 gameId 是否已經存在於 favoriteGamesArray 中
            if (!favoriteGamesArray.includes(gameId)) {
                // 如果不存在，則將 gameId 添加到 favoriteGamesArray 中
                favoriteGamesArray.push(gameId);
                // 更新本地存儲
                localStorage.setItem('favoriteGames', favoriteGamesArray.join(','));
            }
        }

        savedFavoriteGames = localStorage.getItem('favoriteGames');
        setSavedFavoriteGames(savedFavoriteGames);

        window.location.reload();
    };

    const removeFavorite = (gameId) => {
        // 如果您使用 React Router，使用 history.push 導航到新頁面
        // history.push(appStoreLink);
        // localStorage.setItem('favoriteGames', '');

        var savedFavoriteGames = localStorage.getItem('favoriteGames');


        var favoriteGamesArray = savedFavoriteGames.split(',');

        // 檢查 gameId 是否已經存在於 favoriteGamesArray 中
        if (favoriteGamesArray.includes(gameId)) {
            //如果存在則刪除
            favoriteGamesArray = favoriteGamesArray.filter(item => item !== gameId);
            // 更新本地存儲
            localStorage.setItem('favoriteGames', favoriteGamesArray.join(','));
        }


        savedFavoriteGames = localStorage.getItem('favoriteGames');

        setSavedFavoriteGames(savedFavoriteGames);

        window.location.reload();

    };
    return (
        <div className="slider-container" style={{ width: '100%', margin: '0 auto' }}>
            {/* <div>
                <h3>已保存的文本:</h3>
                <p>{savedFavoriteGames}</p>
            </div> */}
            <style>{arrowStyles}</style>
            <Slider {...settings}>
                {images.map((image, index) => (
                    <div key={index} style={{
                        position: 'relative',
                    }}>
                        <img src={image.imageUrl}
                            alt={`Slide ${index}`}
                            // style={{ width: '124px', height: '124px', objectFit: 'cover', paddingLeft: '2px', paddingRight: '2px' }}
                            style={{
                                width: imageWidth, height: imageWidth, objectFit: 'cover', marginLeft: spaceWidth, marginRight: spaceWidth, borderRadius: '24px',
                                boxShadow: '4px 4px 6px rgba(0, 0, 0, 0.6)'
                            }}
                            onClick={() => handleImageClick(image.appStoreLink, image.gameId)}
                        />
                        {image.isFavorite ? ( // 如果 image.isFavorite 為 true
                            <img
                                src={removeButtonImg} // 另一張圖片的路徑
                                alt="removeFavorite" // 圖片的替代文本
                                style={{
                                    position: 'relative',
                                    top: - imageWidth,//'-106px',
                                    left: imageWidth - 30,// imageWidth - 120,// '80px',
                                    width: '30px',
                                    height: '30px',
                                    cursor: 'pointer',
                                    zIndex: '2',
                                    marginLeft: spaceWidth,
                                    marginRight: spaceWidth,
                                }}
                                onClick={() => removeFavorite(image.gameId)} // 點擊事件處理函數
                            />
                        ) : ( // 如果 image.isFavorite 為 false
                            <img
                                src={addFavoriteButtonImg} // 添加可點擊的圖片
                                alt="addFavorite" // 圖片的替代文本
                                style={{
                                    position: 'relative',
                                    top: - imageWidth,//'-106px',
                                    left: imageWidth - 30,// imageWidth - 120,// '80px',                                 
                                    width: '30px',
                                    height: '30px',
                                    cursor: 'pointer',
                                    zIndex: '2',
                                    marginLeft: spaceWidth,
                                    marginRight: spaceWidth,
                                }}
                                onClick={() => addFavorite(image.gameId)} // 點擊事件處理函數
                            />

                        )}
                        <div style={{
                            textAlign: 'center', width: imageWidth, height: '20px',
                            marginLeft: spaceWidth,
                            marginRight: spaceWidth,
                            color: '#fff', fontSize: '14px', position: 'relative', top: '-26px'
                        }}>{image.title}</div>

                    </div>
                ))
                }
            </Slider >
        </div >
    );
}

export default SliderMultiple;
