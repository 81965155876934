// // import logo from './logo.svg';
// // import './App.css';
// // import { HashRouter, Route, Switch } from "react-router-dom";
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import MainPage from "./MainPage";
// import GameDetails from "./gameDetails";
// import PageAllGames from "./pageAllGames";
// import PageAllFavorites from './pageAllFavorites';
// import PageAllCombined from './pageAllCombined';
// import AddMyGame from './pageAddMyGames';



// function App() {
//   return (
//     // <Router basename="js">
//     <Router>
//       <Routes>
//         <Route path="/" element={<MainPage />} />
//         <Route path="/gameDetails" element={<GameDetails />} />
//         <Route path="/allGames" element={<PageAllGames />} />
//         <Route path="/allFavorites" element={<PageAllFavorites />} />
//         <Route path="/allCombined" element={<PageAllCombined />} />
//         <Route path="/addMyGame" element={<AddMyGame />} />
//         {/* <Router basename="/js"></Router> */}
//         {/* 定義其他路由 */}
//       </Routes>
//     </Router>
//   );

// }

// export default App;

import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import MainPage from "./MainPage";
import GameDetails from "./gameDetails";
import PageAllGames from "./pageAllGames";
import PageAllFavorites from './pageAllFavorites';
import PageAllCombined from './pageAllCombined';
import AddMyGame from './pageAddMyGames';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/gameDetails" element={<GameDetails />} />
        <Route path="/allGames" element={<PageAllGames />} />
        <Route path="/allFavorites" element={<PageAllFavorites />} />
        <Route path="/allCombined" element={<PageAllCombined />} />
        <Route path="/addMyGame" element={<AddMyGame />} />
      </Routes>
    </Router>
  );
}

export default App;