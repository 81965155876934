import React, { useState, useEffect, useCallback, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import gameData from "./data/gameData_en-US.json";
import { Link } from 'react-router-dom';
import overlayImage from './images/img_featuredgame_textbg@2x.png';
import addFavoriteButtonImg from './images/btn_favorite@2x.png'; // 導入返回按鈕圖像
import removeButtonImg from './images/btn_favorite_focus@2x.png'; // 導入返回按鈕圖像
function SliderSimple() {
    const [images, setImages] = useState([]);
    const [savedFavoriteGames, setSavedFavoriteGames] = useState('');

    const [gameData, setGameData] = useState(null);
    const [imageHeight, setImageHeight] = useState(160);

    const sliderRef = useRef(null);
    const isUserInteractingRef = useRef(false);


    const pauseAutoplay = useCallback(() => {
        isUserInteractingRef.current = true;
        if (sliderRef.current) {
            sliderRef.current.slickPause();
        }
    }, []);

    const resumeAutoplay = useCallback(() => {
        setTimeout(() => {
            isUserInteractingRef.current = false;
            if (sliderRef.current) {
                sliderRef.current.slickPlay();
            }
        }, 0);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const systemLanguage = localStorage.getItem('language');

                var response = await fetch("./data/gameData_" + systemLanguage + ".json");

                let data; // 在 try 塊外部聲明一個變量
                try {
                    data = await response.json();
                    // 在這裡處理獲取到的 JSON 格式數據
                    console.log("成功獲取到 JSON 格式的數據:", data);
                } catch (error) {
                    // JSON 格式轉換失敗，處理錯誤情況
                    response = await fetch("./data/gameData_en-US.json");
                    data = await response.json();
                    console.error("獲取 JSON 格式數據失敗:", error);
                }

                const editorsChoose = data.EditorsChoose;
                const extractedImages = editorsChoose.map(item => {
                    // 檢查遊戲是否在 favoriteGames 中
                    const isFavorite = savedFavoriteGames !== null && savedFavoriteGames.split(',').includes(item.id);
                    return {
                        bigImageUrl: item.bigImageUrl,
                        imageUrl: item.imageUrl,
                        title: item.title, // 遊戲名稱
                        subTitle: item.subTitle,
                        description: item.description,
                        appStoreLink: item.appStoreLink, // App Store 鏈接
                        gameId: item.id, // 遊戲 ID
                        isFavorite: isFavorite // 遊戲是否收藏
                    };
                });
                setImages(extractedImages);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();

        // 添加一個新的效果來計算和設置圖像高度
        const updateImageHeight = () => {
            const screenWidth = window.innerWidth;
            const aspectRatio = 2.168 / 1; // 假設原始圖像比例為16:9
            const newHeight = screenWidth / aspectRatio;
            setImageHeight(newHeight);
        };

        // 初始調用和添加事件監聽器
        updateImageHeight();
        window.addEventListener('resize', updateImageHeight);

        // 清理函數
        return () => window.removeEventListener('resize', updateImageHeight);

    }, [savedFavoriteGames]);

    useEffect(() => {
        const savedFavoriteGames = localStorage.getItem('favoriteGames');
        if (savedFavoriteGames) {
            setSavedFavoriteGames(savedFavoriteGames);
        }
    }, [savedFavoriteGames]);

    const settings = {
        dots: false,
        infinite: true,
        speed: 100,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: 0, // 確保箭頭顯示   
        autoplay: true,
        autoplaySpeed: 5000,
        // beforeChange: pauseAutoplay,
        // afterChange: resumeAutoplay,
    };
    const handleTouchStart = pauseAutoplay;
    const handleTouchEnd = resumeAutoplay;

    useEffect(() => {
        if (sliderRef.current) {
            sliderRef.current.slickPlay();
        }
    }, [gameData]);


    const arrowStyles = `
    .slick-prev:before, .slick-next:before {
      color: blue; // 設置箭頭顏色
    }  `;

    const handleImageClick = (appStoreLink, gameId) => {
        //       var savedFavoriteGames = localStorage.getItem('favoriteGames');

        // if ((savedFavoriteGames == null) || (savedFavoriteGames == '')) {
        //     localStorage.setItem('favoriteGames', gameId);
        // } else {
        //     // localStorage.setItem('favoriteGames', savedFavoriteGames + "," + gameId);
        //     var favoriteGamesArray = savedFavoriteGames.split(',');

        //     // 檢查 gameId 是否已經存在於 favoriteGamesArray 中
        //     if (!favoriteGamesArray.includes(gameId)) {
        //         // 如果不存在，則將 gameId 添加到 favoriteGamesArray 中
        //         favoriteGamesArray.push(gameId);
        //         // 更新本地存儲
        //         localStorage.setItem('favoriteGames', favoriteGamesArray.join(','));

        //     } else {
        //         //如果存在則刪除

        //         favoriteGamesArray = favoriteGamesArray.filter(item => item !== gameId);
        //         // 更新本地存儲
        //         localStorage.setItem('favoriteGames', favoriteGamesArray.join(','));
        //     }
        // }
        // savedFavoriteGames = localStorage.getItem('favoriteGames');

        // setSavedFavoriteGames(savedFavoriteGames);

        // 如果您使用原生的網頁導航，可以使用 window.location.href
        window.location.href = appStoreLink;
    };

    const addFavorite = (gameId) => {
        // 如果您使用 React Router，使用 history.push 導航到新頁面
        // history.push(appStoreLink);
        // localStorage.setItem('favoriteGames', '');

        var savedFavoriteGames = localStorage.getItem('favoriteGames');
        var favoriteGamesArray;

        if (savedFavoriteGames === null || savedFavoriteGames === undefined || savedFavoriteGames === '') {
            // localStorage 中沒有存儲 'favoriteGames' 數據
            // 進行相應的處理
            favoriteGamesArray = [gameId]; // 初始化 favoriteGamesArray
            // 更新本地存儲
            localStorage.setItem('favoriteGames', favoriteGamesArray.join(','));
        } else {
            // localStorage 中已經存儲了 'favoriteGames' 數據
            favoriteGamesArray = savedFavoriteGames.split(',');
            // 檢查 gameId 是否已經存在於 favoriteGamesArray 中
            if (!favoriteGamesArray.includes(gameId)) {
                // 如果不存在，則將 gameId 添加到 favoriteGamesArray 中
                favoriteGamesArray.push(gameId);
                // 更新本地存儲
                localStorage.setItem('favoriteGames', favoriteGamesArray.join(','));
            }
        }

        savedFavoriteGames = localStorage.getItem('favoriteGames');
        setSavedFavoriteGames(savedFavoriteGames);

        window.location.reload();
    };
    // const addFavorite = (gameId) => {
    //     // 如果您使用 React Router，使用 history.push 導航到新頁面
    //     // history.push(appStoreLink);
    //     // localStorage.setItem('favoriteGames', '');
    //     var savedFavoriteGames = localStorage.getItem('favoriteGames');


    //     var favoriteGamesArray = savedFavoriteGames.split(',');

    //     // 檢查 gameId 是否已經存在於 favoriteGamesArray 中
    //     if (!favoriteGamesArray.includes(gameId)) {
    //         // 如果不存在，則將 gameId 添加到 favoriteGamesArray 中
    //         favoriteGamesArray.push(gameId);
    //         // 更新本地存儲
    //         localStorage.setItem('favoriteGames', favoriteGamesArray.join(','));
    //     }

    //     savedFavoriteGames = localStorage.getItem('favoriteGames');

    //     setSavedFavoriteGames(savedFavoriteGames);

    //     window.location.reload();

    // };
    const removeFavorite = (gameId) => {
        // 如果您使用 React Router，使用 history.push 導航到新頁面
        // history.push(appStoreLink);
        // localStorage.setItem('favoriteGames', '');
        var savedFavoriteGames = localStorage.getItem('favoriteGames');


        var favoriteGamesArray = savedFavoriteGames.split(',');

        // 檢查 gameId 是否已經存在於 favoriteGamesArray 中
        if (!favoriteGamesArray.includes(gameId)) {
            // // 如果不存在，則將 gameId 添加到 favoriteGamesArray 中
            // favoriteGamesArray.push(gameId);
            // // 更新本地存儲
            // localStorage.setItem('favoriteGames', favoriteGamesArray.join(','));

        } else {
            //如果存在則刪除

            favoriteGamesArray = favoriteGamesArray.filter(item => item !== gameId);
            // 更新本地存儲
            localStorage.setItem('favoriteGames', favoriteGamesArray.join(','));
        }


        savedFavoriteGames = localStorage.getItem('favoriteGames');

        setSavedFavoriteGames(savedFavoriteGames);

        window.location.reload();

    };
    return (

        <div className="slider-container" style={{ width: '100%', margin: '0 auto', margin: '0', padding: '0' }}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
        >
            {/* <div>
                <h3>已保存的文本:</h3>
                <p>{savedFavoriteGames}</p>
            </div> */}

            {/* <style>{arrowStyles}</style> */}
            <Slider ref={sliderRef} {...settings}>
                {images.map((image, index) => (
                    <div key={index}>
                        {/* <img src={image.original} alt={`Slide ${index}`} style={{ width: '100%' }} /> */}
                        <div style={{ marginLeft: "8px", marginRight: "8px" }}>
                            {/* <Link to={`/gameDetails/${image.gameId}`} style={{ marginLeft: "20px" }}> */}
                            {/* <Link to={{
                                pathname: '/gameDetails',
                            }}
                                state={{
                                    gameDetail: {
                                        id: image.gameId,
                                        title: image.title,
                                        bigImageUrl: image.bigImageUrl,
                                        description: image.description,
                                        appStoreLink: image.appStoreLink
                                    }
                                }}>
                                <img src={image.imageUrl}
                                    alt={`Slide ${index}`}
                                    style={{ width: '100%' }}
                                // onClick={() => handleImageClick(image.appStoreLink)}
                                /></Link> */}

                            <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                                <img src={image.bigImageUrl}
                                    alt={`Slide ${index}`}
                                    style={{
                                        width: '100%',
                                        height: `${imageHeight}px`,
                                        borderRadius: '10px',
                                        objectFit: 'cover'
                                    }}
                                    onClick={() => handleImageClick(image.appStoreLink, image.gameId)}
                                />

                                {image.isFavorite ? (
                                    <img
                                        src={removeButtonImg}
                                        alt="removeFavorite"
                                        style={{
                                            position: 'absolute',
                                            top: -3,
                                            right: '0px',
                                            width: '30px',
                                            height: '30px',
                                            zIndex: 3,
                                        }}
                                        onClick={() => removeFavorite(image.gameId)}
                                    />
                                ) : (
                                    <img
                                        src={addFavoriteButtonImg}
                                        alt="addFavorite"
                                        style={{
                                            position: 'absolute',
                                            top: -3,
                                            right: 0,
                                            width: '30px',
                                            height: '30px',
                                            cursor: 'pointer',
                                            zIndex: 3,
                                        }}
                                        onClick={() => addFavorite(image.gameId)}
                                    />
                                )}

                                <img src={overlayImage} alt="Overlay" style={{
                                    position: 'absolute', top: 'auto', left: 0, bottom: 0, width: '100%', zIndex: 1, borderBottomLeftRadius: '10px', // 為overlay添加圓角
                                    borderBottomRightRadius: '10px'
                                }} />
                                <div style={{ textAlign: 'left', position: 'absolute', bottom: 28, width: '100%', color: '#fff', padding: '8px', zIndex: 2, fontSize: 22, fontWeight: 'bold' }}>{image.title}</div>
                                <div style={{ textAlign: 'left', position: 'absolute', bottom: 4, width: '100%', color: '#fff', padding: '10px', zIndex: 2, fontSize: 16 }}>{image.subTitle}</div>
                            </div>

                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default SliderSimple;
