import React, { useState, useEffect } from "react";
import styled from "styled-components";

import addFavoriteButtonImg from './images/btn_favorite@2x.png';
import removeButtonImg from './images/btn_favorite_focus@2x.png';
import removeMyGameImg from './images/btn_delete@2x.png';
import bottomLogo from './images/img_bottom@2x.png';

// const screenWidth = window.innerWidth;
// const imageWidth = screenWidth * 0.3;
const screenWidth = Math.min(window.innerWidth, window.innerHeight);
const imageWidth = screenWidth * 0.3;
const imageHeight = imageWidth;

// 保留原有的styled components...
//height: calc(100vh - 200px); // 減去 footer 的高度
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%; /* 螢幕寬度的 80% */
   height: calc(100vh - 110px ); // 減去 footer 的高度  
  overflow-y: auto;
  padding: 10px;
  box-sizing: border-box;
    margin: 0 auto;
    padding-bottom: 60px; // 添加額外的底部 padding
`;

const ImageItem = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 10px;
  position: relative;
`;
// display: flex;
// align-items: flex-start; /* 将图像项向上对齐 */
// width: 100%;
// padding: 10px;
// box-sizing: border-box;

const Image = styled.img`
  width: 124px;
  height: 124px;
  object-fit: cover;
  border-radius: 24px;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.6);
`;

const RemoveButton = styled.img`
  position: absolute;
  top: 0;
  left: 94px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 1;
`;

const TextContainer = styled.div`
  flex-grow: 1;
  margin-top: -4px; /* 調整文字的上邊距 */
`;

const Title = styled.h4`
  margin: 0;
  padding-bottom: 0px;
  color: #fff;
`;

const SubTitle = styled.div`
    margin-top: 4px;  
  color: #fff;
  font-size: 14px;
`;

const FooterContainer = styled.div`
 position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #000;
  height: 30px;
  color: #fff;
  padding: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FooterText = styled.div`
  text-align: left;
  color: #fff;
  font-size: 14px;
  margin-left: 20px;
`;

const FooterImage = styled.img`
  height: 20px;
  margin-right: 10px;
`;



// const MoveButtonsContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;  // 新增這行來水平居中
//   position: absolute;   // 使用絕對定位
//   right: 50%;           // 將右邊緣置於容器中心
//   transform: translateX(50%);  // 向右移動自身寬度的一半
//   top: 0;               // 對齊頂部
//   bottom: 0;            // 對齊底部
//   width: 30px;          // 設置一個固定寬度
// `;

const MoveButton = styled.button`
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 2px 0;  // 增加一些垂直間距
  font-size: 16px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.7);  // 半透明白色背景
  border: none;
  border-radius: 15px;  // 圓角按鈕
  display: flex;
  justify-content: center;
  align-items: center;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const MoveButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
`;
const MoveToTopButton = styled.button`
  width: 30px;
  height: 30px;
  padding: 0;
  margin-left: 10px;
  font-size: 16px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
// const MoveButton = styled.button`
//   width: 30px;
//   height: 30px;
//   margin: 2px 0;
//   padding: 0;

//   font-size: 16px;
//   cursor: pointer;
//   &:disabled {
//     opacity: 0.5;
//     cursor: not-allowed;
//   }
// `;

const EditToggleContainer = styled.div`
  position: fixed;
  top: 18px;
  right: 10px;
  display: flex;
  align-items: center;
  z-index: 1000;
`;
const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 42px;
  height: 26px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;

    &:before {
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      transition: .4s;
      border-radius: 50%;
    }
  }

  input:checked + span {
    background-color: #2196F3;
  }

  input:checked + span:before {
    transform: translateX(16px);
  }
`;
const EditLabel = styled.span`
  color: #fff;
  margin-right: 10px;
`;

function TwoColumnCombined() {
  const [editMode, setEditMode] = useState(false);

  const [images, setImages] = useState([]);
  const [savedFavoriteGames, setSavedFavoriteGames] = useState('');
  const [savedMyGames, setSavedMyGames] = useState('');
  // const systemLanguage = localStorage.getItem('language');
  const [systemLanguage, setSystemLanguage] = useState('en-US'); // 設置默認語言為英語
  const [gameOrder, setGameOrder] = useState([]);

  const handleImageClick = (appStoreLink) => {
    window.location.href = appStoreLink;
  };

  const removeFavorite = (gameId) => {
    var savedFavoriteGames = localStorage.getItem('favoriteGames');
    var favoriteGamesArray = savedFavoriteGames.split(',');
    favoriteGamesArray = favoriteGamesArray.filter(item => item !== gameId);
    localStorage.setItem('favoriteGames', favoriteGamesArray.join(','));
    setSavedFavoriteGames(favoriteGamesArray.join(','));

    const newOrder = gameOrder.filter(id => id !== gameId);
    setGameOrder(newOrder);
    localStorage.setItem('gameOrder', JSON.stringify(newOrder));
  };

  const removeMyGame = (gameId) => {
    var savedMyGames = JSON.parse(localStorage.getItem('myGames') || '[]');
    savedMyGames = savedMyGames.filter(game => game.id !== gameId);
    localStorage.setItem('myGames', JSON.stringify(savedMyGames));
    setSavedMyGames(JSON.stringify(savedMyGames));

    const newOrder = gameOrder.filter(id => id !== gameId);
    setGameOrder(newOrder);
    localStorage.setItem('gameOrder', JSON.stringify(newOrder));
  };

  const moveToTop = (gameId) => {
    const newOrder = [gameId, ...gameOrder.filter(id => id !== gameId)];
    setGameOrder(newOrder);
    localStorage.setItem('gameOrder', JSON.stringify(newOrder));

    // Re-sort the images based on the new order
    const sortedImages = [...images].sort((a, b) => newOrder.indexOf(a.id) - newOrder.indexOf(b.id));
    setImages(sortedImages);
  };

  const moveGame = (gameId, direction) => {

    const currentIndex = gameOrder.indexOf(gameId);
    if (
      (direction === 'up' && currentIndex > 0) ||
      (direction === 'down' && currentIndex < gameOrder.length - 1)
    ) {

      const newOrder = [...gameOrder];
      const swapIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;


      [newOrder[currentIndex], newOrder[swapIndex]] = [newOrder[swapIndex], newOrder[currentIndex]];

      setGameOrder(newOrder);
      localStorage.setItem('gameOrder', JSON.stringify(newOrder));

      // Re-sort the images based on the new order
      const sortedImages = [...images].sort((a, b) => newOrder.indexOf(a.id) - newOrder.indexOf(b.id));
      setImages(sortedImages);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        // 讀取並驗證 systemLanguage
        let lang = localStorage.getItem('language');
        const validLanguages = ['en-US', 'zh-TW', 'ja']; // 假設這些是有效的語言代碼

        if (!lang || !validLanguages.includes(lang)) {
          lang = 'en-US'; // 如果語言無效或為空，使用默認語言
          localStorage.setItem('language', lang); // 將有效語言保存回 localStorage
        }

        setSystemLanguage(lang);

        // 加載收藏的遊戲
        const response = await fetch(`./data/gameData_${systemLanguage}.json`);
        const data = await response.json();
        const editorsChoose = data.GamingCaseCompatible;
        const tempSavedFavoriteGames = localStorage.getItem('favoriteGames');

        let favoriteGames = [];
        if (tempSavedFavoriteGames) {
          const savedGamesArray = tempSavedFavoriteGames.split(',');
          favoriteGames = editorsChoose
            .filter(item => savedGamesArray.includes(item.id))
            .map(item => ({
              ...item,
              type: 'favoriteGame'
            }));
        }

        // 加載我的遊戲
        const savedMyGamesData = localStorage.getItem('myGames');
        let myGames = savedMyGamesData ? JSON.parse(savedMyGamesData) : [];
        myGames = myGames.map(game => ({ ...game, type: 'myGame' }));

        // 合併兩種遊戲
        const allGames = [...favoriteGames, ...myGames];

        // 加載保存的順序
        let order = JSON.parse(localStorage.getItem('gameOrder') || '[]');



        // 比對 allGames 和 order，更新 order
        const allGameIds = allGames.map(game => game.id);
        const newGames = allGameIds.filter(id => !order.includes(id));
        const validOrderedGames = order.filter(id => allGameIds.includes(id));

        // 新的順序：新遊戲在前，然後是有效的舊順序
        const newOrder = [...newGames, ...validOrderedGames];

        // 更新 localStorage
        localStorage.setItem('gameOrder', JSON.stringify(newOrder));

        // 根據新順序排序遊戲
        allGames.sort((a, b) => newOrder.indexOf(a.id) - newOrder.indexOf(b.id));

        setImages(allGames);
        setGameOrder(newOrder);
        // console.log("11111:" + newOrder);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    // Add orientation change event listener
    const handleOrientationChange = () => {
      window.location.reload();
    };
    window.addEventListener('orientationchange', handleOrientationChange);

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, [savedFavoriteGames, savedMyGames, systemLanguage]);

  const [containerHeight, setContainerHeight] = useState(0);
  // useEffect(() => {
  //     const calculateContainerHeight = () => {
  //         const numberOfImages = Math.min(images.length, 4);
  //         const calculatedHeight = numberOfImages * 22;
  //         setContainerHeight(calculatedHeight);
  //     };

  //     calculateContainerHeight();
  // }, [images]);

  return (
    <>
      <EditToggleContainer>
        <EditLabel>{editMode ? "Editing" : "Editing"}</EditLabel>
        <ToggleSwitch>
          <input
            type="checkbox"
            checked={editMode}
            onChange={(e) => setEditMode(e.target.checked)}
          />
          <span></span>
        </ToggleSwitch>
      </EditToggleContainer>
      {/* <Container style={{ height: `${containerHeight}vh` }}> */}
      <Container>
        {images.map((image, index) => (
          <ImageItem key={index}>
            <Image
              src={image.imageUrl}
              alt={`Slide ${index}`}
              onClick={() => handleImageClick(image.appStoreLink)}
              style={{
                width: `${imageWidth}px`,
                height: `${imageHeight}px`,
                objectFit: 'cover',
                paddingLeft: '0px',
                paddingRight: '0px',
                borderRadius: '24px',
                boxShadow: '4px 4px 6px rgba(0, 0, 0, 0.6)'
              }}
            />
            <img
              src={image.type === 'myGame' ? removeMyGameImg : removeButtonImg}
              alt={image.type === 'myGame' ? "Remove My Game" : "Remove Favorite"}
              style={{
                position: 'relative',
                top: '0px',
                left: '-30px',
                width: '30px',
                height: '30px',
                zIndex: '1',
                cursor: 'pointer',
              }}
              onClick={() => image.type === 'myGame' ? removeMyGame(image.id) : removeFavorite(image.id)}
            />
            <TextContainer>
              <Title>{image.title}</Title>
              <SubTitle>{image.subTitle}</SubTitle>
            </TextContainer>
            {editMode && (
              <MoveButtonsContainer>
                <MoveToTopButton onClick={() => moveToTop(image.id)} disabled={index === 0}>
                  ▲
                </MoveToTopButton>
              </MoveButtonsContainer>
            )}
          </ImageItem>
        ))}

        <FooterContainer>
          <FooterText>Region: {systemLanguage}</FooterText>
          <div style={{ textAlign: 'right', marginRight: '20px' }}>
            <img src={bottomLogo} alt="Footer Image" style={{ maxWidth: '100%', height: '30px' }} />
          </div>
        </FooterContainer>
      </Container>


    </>
  );
}

export default TwoColumnCombined;

