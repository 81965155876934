import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from 'react-router-dom';
import bottomLogo from './images/img_bottom@2x.png'; // 導入返回按鈕圖像
import backButtonImg from './images/btn_back@2x.png'; // 導入返回按鈕圖像

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px 20px 60px; /* 上方增加 padding 為按鈕留出空間 */
    overflow-y: auto; /* 允許垂直滾動 */
    height: 100vh; /* 使用全視窗高度 */
    box-sizing: border-box; /* 確保 padding 不會增加元素的總高度 */      
    position: relative; /* 添加這行 */
`;

// padding: 0 20px; /* 左右邊界 */
// display: flex;
// flex-direction: column;
// align-items: center;
// padding: 60px 20px 60px; /* 上方增加 padding 為按鈕留出空間 */
// overflow-y: auto; /* 允許垂直滾動 */
// height: 100vh; /* 使用全視窗高度 */
// box-sizing: border-box; /* 確保 padding 不會增加元素的總高度 */
const WhiteText = styled.p`
    color: white;
    text-align: left;
`;
const backButtonStyle = {
    position: 'fixed', // 改為 'fixed'
    top: '20px',
    left: '20px',
    width: '30px',
    height: '30px',
    zIndex: 1000 // 添加 zIndex
    // position: 'absolute',
    // top: '20px',
    // left: '20px',
    // // zIndex: '9999',
    // width: '30px',
    // height: '30px',
    // marginBottom: '100px'
};
const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #000;
  height: 30px;
  color: #fff;
  padding: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FooterText = styled.div`
  text-align: left;
  color: #fff;
  font-size: 10px;
  margin-left: 10px;
`;

const FooterImage = styled.img`
  max-width: 100%;
  height: 20px;
  margin-right: 10px;
`;
const PageAddMyGames = () => {
    const [url, setUrl] = useState('');
    const [country, setCountry] = useState('');
    const [appId, setAppId] = useState('');
    const [appData, setAppData] = useState(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const defaultLanguage = localStorage.getItem('language') || navigator.language || navigator.userLanguage;
    const initialLanguage = ['ja', 'zh-TW'].includes(defaultLanguage) ? defaultLanguage : 'en-US';

    const [currentLanguage, setCurrentLanguage] = useState(initialLanguage);
    const [systemLanguageData, setSystemLanguageData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const systemLanguage = localStorage.getItem('language');

                var response = await fetch("./data/system_" + systemLanguage + ".json");
                let data; // 在 try 塊外部聲明一個變量
                try {
                    data = await response.json();
                    // 在這裡處理獲取到的 JSON 格式數據
                    console.log("成功獲取到 JSON 格式的數據:", data);
                } catch (error) {
                    // JSON 格式轉換失敗，處理錯誤情況

                    console.error("獲取 JSON 格式數據失敗:", error);
                }

                setSystemLanguageData(data);

                // 添加延遲
                setTimeout(() => {
                    setIsLoading(false);  // 延遲後設置 isLoading 為 false
                }, 1000); // 1000 毫秒 = 1 秒延遲，您可以根據需要調整這個值
            } catch (error) {
                console.error("Error fetching data:", error);
                // 添加延遲
                setTimeout(() => {
                    setIsLoading(false);  // 延遲後設置 isLoading 為 false
                }, 1000); // 1000 毫秒 = 1 秒延遲，您可以根據需要調整這個值
            }
        };

        fetchData();

        // Add orientation change event listener
        const handleOrientationChange = () => {
            window.location.reload();
        };
        window.addEventListener('orientationchange', handleOrientationChange);

        return () => {
            window.removeEventListener('orientationchange', handleOrientationChange);
        };

    }, [currentLanguage]);
    const handleFetchClick = async () => {
        // 解析網址獲取 country 和 appId
        const urlParts = url.split('/');
        const countryValue = urlParts[urlParts.indexOf('app') - 1];
        const appIdValue = urlParts.pop().replace('id', '');

        setCountry(countryValue);
        setAppId(appIdValue);

        // 調用 fetchAppData 函數獲取 App 數據
        await fetchAppData(countryValue, appIdValue);
    };

    const handleAddButtonClick = async () => {
        // 處理添加遊戲按鈕點擊事件
        if (appData) {
            // 構造要保存的對象
            const newData = {
                title: appData.title,
                imageUrl: appData.icon,
                appStoreLink: url
            };

            // 從 localStorage 中獲取現有數據
            const existingData = localStorage.getItem('myGames');
            let dataArray = [];

            // 如果已存在數據，將其轉換為數組
            if (existingData) {
                dataArray = JSON.parse(existingData);
            }

            // 將新數據添加到數組中
            dataArray.push(newData);

            // 將更新後的數組保存到 localStorage 中
            localStorage.setItem('myGames', JSON.stringify(dataArray));

            setShowSuccessMessage(true);

            setTimeout(() => {
                window.history.back();
            }, 1000);
        }
    };

    const fetchAppData = async (country, id) => {
        try {
            const response = await fetch(`https://app-store2.p.rapidapi.com/app/${id}?country=${country}`, {
                method: 'GET',
                headers: {
                    'X-RapidAPI-Key': '545b9fa237mshe7dc87ecdbe84a8p155aaajsn6fe4255c68a7',
                    'X-RapidAPI-Host': 'app-store2.p.rapidapi.com'
                }
            });

            if (response.ok) {
                const data = await response.json();
                setAppData(data);
            } else {
                throw new Error('Failed to fetch App data');
            }
        } catch (error) {
            console.error('Error fetching App data:', error);
        }
    };
    const Input = styled.input`
    width: 80%;
`;
    const systemLanguage = localStorage.getItem('language');
    return (

        <>
            <Link to="/">
                <img src={backButtonImg} alt="Back" style={backButtonStyle} />
            </Link>
            <PageContainer>

                <h1><WhiteText> {systemLanguageData?.addGamePageTitle || ' '}</WhiteText></h1>
                <Input
                    type="text"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    placeholder="Enter App URL"
                    style={{ fontSize: '16px' }}
                />
                <br></br>
                <button onClick={handleFetchClick}>Fetch Data</button>

                {/* 顯示解析後的數據 */}
                {appData && (
                    <div>
                        <h2><WhiteText>App Data:</WhiteText></h2>

                        <WhiteText>Country: {country}</WhiteText>
                        <WhiteText>App ID: {appId}</WhiteText>
                        <WhiteText>App Name: {appData.title}</WhiteText>
                        <WhiteText>Developer: {appData.developer}</WhiteText>
                        <img
                            src={appData.icon}
                            alt={appData.title}
                            style={{
                                width: '100px',
                                height: '100px',
                            }}
                        />
                        {/* 其他需要顯示的數據 */}
                    </div>
                )}
                <br></br>
                {showSuccessMessage && <WhiteText> <p>Add successful!</p></WhiteText>}
                <br></br>
                <button onClick={handleAddButtonClick}>Add To My Game</button>

                <div style={{
                    color: '#fff',
                    marginTop: '24px',
                    marginBottom: '60px',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    padding: '15px',
                    borderRadius: '10px',
                    maxWidth: '90%',
                    width: 'fit-content', // 讓寬度適應內容
                    marginLeft: 'auto',   // 左右 margin 設為 auto 實現水平居中
                    marginRight: 'auto'
                }}>
                    {isLoading ? (

                        <div>
                            Loading...</div>
                    ) : (
                        <>
                            <ol style={{ paddingTop: 'px', paddingLeft: '20px' }}>
                                <li style={{ marginBottom: '5px' }}>
                                    {systemLanguageData?.addGameDescription1 || ' '}
                                </li>
                                <li style={{ marginBottom: '5px' }}>
                                    {systemLanguageData?.addGameDescription2 || ' '}
                                    <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 24 24" width="18" style={{ marginLeft: '5px' }}>
                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                        <path d="M16 5l-1.42 1.42-1.59-1.59V16h-1.98V4.83L9.42 6.42 8 5l4-4 4 4zm4 5v11c0 1.1-.9 2-2 2H6c-1.11 0-2-.9-2-2V10c0-1.11.89-2 2-2h3v2H6v11h12V10h-3V8h3c1.1 0 2 .89 2 2z" fill="white" />
                                    </svg>
                                </li>
                                <li style={{ marginBottom: '5px' }}>
                                    {systemLanguageData?.addGameDescription3 || ' '}
                                    <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 1024 1024" width="18" style={{ margin: '0 5px', flexShrink: 0 }}>
                                        <path fill="white" d="M560 682.2l-2.4 0.2c-7.2 0.8-14 4-19.2 9l-129.2 129.2c-27.4 27.4-64 42.4-103 42.4s-75.6-15-103-42.4c-27.4-27.4-42.4-64-42.4-103s15-75.6 42.4-103l137.2-137.2c7-7 14.6-13.2 22.8-18.6 9.2-6 19.2-11.2 29.6-15 9.6-3.6 19.8-6 30-7.4 6.8-1 13.8-1.4 20.4-1.4 2.8 0 5.6 0.2 9.2 0.4 35.4 2.2 68.8 17.2 93.6 42 15.4 15.4 27.2 34.2 34.2 54.6 5.6 16 22.4 25 38.6 20.2 0.2 0 0.4-0.2 0.6-0.2 0.2 0 0.4 0 0.4-0.2 16.2-5 25.6-22 21-38.2-8.8-31.2-24.4-57.4-49.2-82-31.2-31.2-71.8-51.6-115.2-58.6-3.8-0.6-7.6-1.2-11.4-1.6-7.4-0.8-14.8-1.2-22.2-1.2-5.2 0-10.4 0.2-15.4 0.6-10.8 0.8-21.6 2.4-32.4 5-2.2 0.4-4.2 1-6.4 1.6-13.4 3.6-26.6 8.4-39 14.6-20.6 10.2-39.2 23.4-55.4 39.8l-137.2 137.2C117.8 608.8 96 661.6 96 718c0 56.4 21.8 109.2 61.4 148.8C197 906.2 249.8 928 306 928c56.4 0 109.2-21.8 148.8-61.4l130.6-130.6c20.8-21 4-56.6-25.4-53.8z" />
                                        <path fill="white" d="M866.6 157.4C827 117.8 774.2 96 718 96s-109.2 21.8-148.8 61.4l-127.4 127.4c-19.4 19.4-7.2 52.6 20.2 54.8 9.4 0.8 18.6-2.6 25.4-9.2l127.6-127.2c27.4-27.4 64-42.4 103-42.4s75.6 15 103 42.4c27.4 27.4 42.4 64 42.4 103s-15 75.6-42.4 103l-137.2 137.2c-7 7-14.6 13.2-22.8 18.6-9.2 6-19.2 11.2-29.6 15-9.6 3.6-19.8 6-30 7.4-6.8 1-13.8 1.4-20.4 1.4-2.8 0-5.8-0.2-9.2-0.4-35.4-2.2-68.8-17.2-93.6-42-14.6-14.6-25.6-32-32.8-51-5.8-15.4-22.2-23.8-38.2-19.6-17.8 4.6-28.2 23.4-22.6 41 9 28 24.2 51.8 47.4 75l0.4 0.4c33.8 33.8 78.8 55.2 126.6 60.2 7.4 0.8 14.8 1.2 22.2 1.2 5.2 0 10.4-0.2 15.6-0.6 13-1 26-3.2 38.6-6.4 13.4-3.6 26.6-8.4 39-14.6 20.6-10.2 39.2-23.4 55.4-39.8l137.2-137.2c39.6-39.6 61.4-92.4 61.4-148.8s-22.2-109.2-61.8-148.8z" />
                                    </svg>
                                </li>
                                <li style={{ marginBottom: '5px' }}>
                                    {systemLanguageData?.addGameDescription4 || ' '}
                                </li>
                                <li style={{ marginBottom: '5px' }}>
                                    {systemLanguageData?.addGameDescription5 || ' '}
                                </li>
                                <li>
                                    {systemLanguageData?.addGameDescription6 || ''}

                                </li>
                            </ol>
                        </>
                    )}
                </div>
            </PageContainer>
            );






            {/* <FooterContainer>
                <FooterText>Region: {systemLanguage}</FooterText>
                <FooterImage src={bottomLogo} alt="Footer Image" />
            </FooterContainer> */}
            <FooterContainer>
                <FooterText>Region: {systemLanguage}</FooterText>
                <div style={{ textAlign: 'right', marginRight: '20px' }}>
                    <img src={bottomLogo} alt="Footer Image" style={{ maxWidth: '100%', height: '30px' }} />
                </div>
            </FooterContainer>
        </>
    );
}


export default PageAddMyGames;
