import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from 'react-router-dom';

import addFavoriteButtonImg from './images/btn_favorite@2x.png';
// import removeButtonImg from './images/btn_favorite_focus@2x.png';
import bottomLogo from './images/img_bottom@2x.png';
import removeMyGameImg from './images/btn_delete@2x.png'; // 導入返回按鈕圖像

const screenWidth = window.innerWidth;
const imageWidth = screenWidth * 0.3;
const imageHeight = imageWidth;

// 保留原有的 styled components...
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 80%; /* 屏幕寬度的80% */
  height:88vh;
  margin: 0 auto; /* 水平居中 */
  justify-content: space-around; /* 圖片水平居中 */
  align-items: flex-start; /* 圖片頂部對齊 */
  overflow-y: auto; /* 添加垂直滾動 */  
`;

const ImageItem = styled.div`
  display: flex;
  align-items: flex-start; /* 將圖像項向上對齊 */
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
`;

const Image = styled.img`
  width: 124px;
  height: 124px;
  objectFit: cover;
  paddingLeft: 2px';
  paddingRight: 2px;
`;

const TextContainer = styled.div`
  flex-grow: 1;
  margin-top: -10px; /* 調整文字的上邊距 */
`;
const Title = styled.h4`
  margin: 0;
  padding-bottom: 0px;
  color: #fff;
`;

const SubTitle = styled.div`
  margin: 0;
  color: #fff;
  font-size: 14px; 
`;
const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #000;
  height: 20px;
  color: #fff;
  padding: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FooterText = styled.div`
  text-align: left;
  color: #fff;
  font-size: 10px;
  margin-left: 10px;
`;

const FooterImage = styled.img`
  max-width: 100%;
  height: 20px;
  margin-right: 10px;
`;

function TwoColumnForMyGames() {
    const [images, setImages] = useState([]);
    const [savedMyGames, setSavedMyGames] = useState('');
    const systemLanguage = localStorage.getItem('language');

    useEffect(() => {
        const savedData = localStorage.getItem('myGames');
        if (savedData) {
            const data = JSON.parse(savedData);
            setImages(data);
        }
    }, [savedMyGames]);

    const handleImageClick = (appStoreLink) => {
        window.location.href = appStoreLink;
    };

    const removeFromMyGame = (index) => {
        var savedMyGames = localStorage.getItem('myGames');
        var myGamesArray = JSON.parse(savedMyGames);
        var numberOfGames = myGamesArray.length;

        if (index >= 0 && index < numberOfGames) {
            myGamesArray.splice(index, 1);
            localStorage.setItem('myGames', JSON.stringify(myGamesArray));
            setImages(myGamesArray);
            setSavedMyGames(JSON.stringify(myGamesArray));
        } else {
            console.error('Invalid index:', index);
        }
    };

    const [containerHeight, setContainerHeight] = useState(0);

    useEffect(() => {
        const calculateContainerHeight = () => {
            const numberOfImages = Math.min(images.length, 4);
            const calculatedHeight = numberOfImages * 22;
            setContainerHeight(calculatedHeight);
        };

        calculateContainerHeight();
    }, [images]);

    return (
        <>
            <Container style={{ height: `${containerHeight}vh` }}>
                {images.map((image, index) => (
                    <ImageItem key={index}>
                        <Image 
                            src={image.imageUrl}
                            alt={`Slide ${index}`}
                            onClick={() => handleImageClick(image.appStoreLink)}
                            style={{
                                width: `${imageWidth}px`, 
                                height: `${imageHeight}px`, 
                                objectFit: 'cover', 
                                paddingLeft: '0px', 
                                paddingRight: '0px', 
                                borderRadius: '24px',
                                boxShadow: '4px 4px 6px rgba(0, 0, 0, 0.6)'
                            }}
                        />
                        <img
                            src={removeMyGameImg}
                            alt="Remove From MyGames"
                            style={{
                                position: 'relative',
                                top: '0px',
                                left: '-30px',
                                width: '30px',
                                height: '30px',
                                cursor: 'pointer',
                                zIndex: '1',
                            }}
                            onClick={() => removeFromMyGame(index)}
                        />
                        <TextContainer>
                            <Title>{image.title}</Title>
                            <SubTitle>{image.subTitle || ''}</SubTitle>
                        </TextContainer>
                    </ImageItem>
                ))}
            </Container>

            <FooterContainer>
                <FooterText>Region: {systemLanguage}</FooterText>
                <FooterImage src={bottomLogo} alt="Footer Image" />
            </FooterContainer>
        </>
    );
}

export default TwoColumnForMyGames;