import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import removeMyGameImg from './images/btn_delete@2x.png';
import removeButtonImg from './images/btn_favorite_focus@2x.png';

function SliderCombined() {
    const [combinedGames, setCombinedGames] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [savedMyGames, setSavedMyGames] = useState('');
    const [savedFavoriteGames, setSavedFavoriteGames] = useState('');

    useEffect(() => {
        const loadGames = async () => {
            console.log("Starting to load games");
            const systemLanguage = localStorage.getItem('language') || 'en-US';

            try {
                // 加載收藏的遊戲
                const response = await fetch(`./data/gameData_${systemLanguage}.json`);
                const data = await response.json();
                const editorsChoose = data.GamingCaseCompatible;
                const tempSavedFavoriteGames = localStorage.getItem('favoriteGames');

                let favoriteGames = [];
                if (tempSavedFavoriteGames) {
                    const savedGamesArray = tempSavedFavoriteGames.split(',');
                    favoriteGames = editorsChoose
                        .filter(item => savedGamesArray.includes(item.id))
                        .map(item => ({
                            ...item,
                            type: 'favoriteGame'
                        }));
                }

                // 加載我的遊戲
                const savedMyGamesData = localStorage.getItem('myGames');
                let myGames = savedMyGamesData ? JSON.parse(savedMyGamesData) : [];
                myGames = myGames.map(game => ({ ...game, type: 'myGame' }));

                // 合併兩種遊戲
                const allGames = [...favoriteGames, ...myGames];

                // 加載保存的順序
                let order = JSON.parse(localStorage.getItem('gameOrder') || '[]');

                // 比對 allGames 和 order，更新 order
                const allGameIds = allGames.map(game => game.id || game.title);
                const newGames = allGameIds.filter(id => !order.includes(id));
                const validOrderedGames = order.filter(id => allGameIds.includes(id));

                // 新的順序：新遊戲在前，然後是有效的舊順序
                const newOrder = [...newGames, ...validOrderedGames];

                // 更新 localStorage
                localStorage.setItem('gameOrder', JSON.stringify(newOrder));

                // 根據新順序排序遊戲
                allGames.sort((a, b) => newOrder.indexOf(a.id || a.title) - newOrder.indexOf(b.id || b.title));

                console.log("Combined Games:", allGames);
                setCombinedGames(allGames);
            } catch (error) {
                console.error("Error loading game data:", error);
            } finally {
                setIsLoading(false);
            }
        };

        loadGames();
    }, [savedMyGames, savedFavoriteGames]);
    // useEffect(() => {
    //     const loadGames = async () => {
    //         console.log("Starting to load games");

    //         // // 加載我的遊戲
    //         // const savedMyGamesData = localStorage.getItem('myGames');
    //         // // console.log("Saved My Games Data:", savedMyGamesData);
    //         // let myGames = savedMyGamesData ? JSON.parse(savedMyGamesData) : [];
    //         // myGames = myGames.map(game => ({ ...game, type: 'myGame' }));
    //         // // console.log("Processed My Games:", myGames);

    //         // // 加載收藏的遊戲
    //         const systemLanguage = localStorage.getItem('language') || 'en-US';
    //         // console.log("System Language:", systemLanguage);
    //         // try {
    //         //     const response = await fetch(`./data/gameData_${systemLanguage}.json`);
    //         //     const data = await response.json();
    //         //     console.log("Fetched Game Data:", data);
    //         //     const editorsChoose = data.GamingCaseCompatible;
    //         //     const extractedImages = editorsChoose.map(item => ({
    //         //         bigImageUrl: item.bigImageUrl,
    //         //         imageUrl: item.imageUrl,
    //         //         title: item.title,
    //         //         description: item.description,
    //         //         appStoreLink: item.appStoreLink,
    //         //         gameId: item.id,
    //         //         type: 'favoriteGame'
    //         //     }));

    //         //     const savedFavoriteGamesIds = localStorage.getItem('favoriteGames');
    //         //     let favoriteGames = [];
    //         //     if (savedFavoriteGamesIds) {
    //         //         const savedGamesArray = savedFavoriteGamesIds.split(',');
    //         //         favoriteGames = extractedImages.filter(image => savedGamesArray.includes(image.gameId));
    //         //     }

    //         //     // 合併兩種遊戲
    //         //     var allGames = [...myGames, ...favoriteGames];
    //         //     // console.log("Combined Games:", allGames);
    //         //     // setCombinedGames(allGames);


    //         //     // 從 localStorage 讀取排序
    //         //     const savedOrder = localStorage.getItem('gameOrder');
    //         //     if (savedOrder) {
    //         //         const orderArray = JSON.parse(savedOrder);
    //         //         // 根據保存的順序重新排序遊戲
    //         //         allGames = orderArray.map(id =>
    //         //             allGames.find(game =>
    //         //                 (game.type === 'myGame' && game.title === id) ||
    //         //                 (game.type === 'favoriteGame' && game.gameId === id)
    //         //             )
    //         //         ).filter(Boolean); // 過濾掉未找到的遊戲
    //         //     }

    //         try {
    //             // 加載收藏的遊戲
    //             const response = await fetch(`./data/gameData_${systemLanguage}.json`);
    //             const data = await response.json();
    //             const editorsChoose = data.GamingCaseCompatible;
    //             const tempSavedFavoriteGames = localStorage.getItem('favoriteGames');

    //             let favoriteGames = [];
    //             if (tempSavedFavoriteGames) {
    //                 const savedGamesArray = tempSavedFavoriteGames.split(',');
    //                 favoriteGames = editorsChoose
    //                     .filter(item => savedGamesArray.includes(item.id))
    //                     .map(item => ({
    //                         ...item,
    //                         type: 'favoriteGame'
    //                     }));
    //             }

    //             // 加載我的遊戲
    //             const savedMyGamesData = localStorage.getItem('myGames');
    //             let myGames = savedMyGamesData ? JSON.parse(savedMyGamesData) : [];
    //             myGames = myGames.map(game => ({ ...game, type: 'myGame' }));

    //             // 合併兩種遊戲
    //             const allGames = [...favoriteGames, ...myGames];

    //             // 加載保存的順序
    //             let order = JSON.parse(localStorage.getItem('gameOrder') || '[]');

    //             // 比對 allGames 和 order，更新 order
    //             const allGameIds = allGames.map(game => game.id);
    //             const newGames = allGameIds.filter(id => !order.includes(id));
    //             const validOrderedGames = order.filter(id => allGameIds.includes(id));

    //             // 新的順序：新遊戲在前，然後是有效的舊順序
    //             const newOrder = [...newGames, ...validOrderedGames];

    //             // 更新 localStorage
    //             localStorage.setItem('gameOrder', JSON.stringify(newOrder));

    //             // 根據新順序排序遊戲
    //             allGames.sort((a, b) => newOrder.indexOf(a.id) - newOrder.indexOf(b.id));


    //             console.log("Combined Games:", allGames);
    //             setCombinedGames(allGames);
    //             // setGameOrder(newOrder);


    //         } catch (error) {
    //             console.error("Error loading game data:", error);
    //         } finally {
    //             setIsLoading(false);
    //         }
    //     };

    //     loadGames();
    // }, [savedMyGames, savedFavoriteGames]);
    const imageWidth = 84  //106
    const vCount = 4 //3
    const wCount = 7 //5
    const orientation = window.innerHeight > window.innerWidth ? 'vertical' : 'horizontal';
    const slidesToShow = orientation === 'vertical' ? Math.min(vCount, combinedGames.length) : Math.min(wCount, combinedGames.length); //垂直3 水平5個
    const spaceWidth = slidesToShow < vCount ? 9 + 'px' : ((window.innerWidth - (slidesToShow * imageWidth)) / (slidesToShow * 2)) + 'px';// orientation === 'vertical' ? (window.innerWidth - slidesToShow * 106) / 3 + 'px' : (window.innerWidth - slidesToShow * 106) / 3 + 'px'


    // const settings = {
    //     dots: false,
    //     infinite: false,
    //     speed: 200,
    //     slidesToShow: slidesToShow,
    //     slidesToScroll: slidesToShow + 1,
    //     variableWidth: true,
    //     arrows: false
    // };
    // const settings = {
    //     dots: false,
    //     infinite: false,
    //     speed: 100,
    //     swipeToSlide: true,
    //     // slidesToShow: slidesToShow,
    //     // slidesToScroll:  slidesToShow,
    //     // slidesToShow: images.length < 3 ? images.length : 3,
    //     // slidesToScroll: images.length < 3 ? images.length + 1 : 3,
    //     variableWidth: true, // 根據圖片的寬度自適應
    //     arrows: false // 確保箭頭顯示

    // };
    const settings = {
        dots: false,
        infinite: false,
        speed: 100,
        // swipeToSlide: true,
        slidesToShow: combinedGames.length < vCount ? combinedGames.length : slidesToShow,
        slidesToScroll: combinedGames.length < vCount ? combinedGames.length : slidesToShow,
        touchThreshold: 50, // 降低觸摸閾值，使滑動更敏感
        variableWidth: true,
        arrows: 0
        // dots: false,
        // infinite: false,
        // speed: 100,
        // // swipeToSlide: true,
        // slidesToShow: combinedGames.length < 3 ? combinedGames.length : slidesToShow,
        // slidesToScroll: combinedGames.length < 3 ? combinedGames.length : slidesToShow,
        // touchThreshold: 50, // 降低觸摸閾值，使滑動更敏感
        // variableWidth: true,
        // arrows: 0
    };
    const handleImageClick = (appStoreLink) => {
        window.location.href = appStoreLink;
    };

    const removeGame = (game) => {
        console.log("Removing game:", game);

        let updatedGames;
        if (game.type === 'myGame') {
            updatedGames = combinedGames.filter(g => !(g.type === 'myGame' && g.title === game.title));
            const updatedMyGames = updatedGames.filter(g => g.type === 'myGame');
            localStorage.setItem('myGames', JSON.stringify(updatedMyGames));
            setSavedMyGames(JSON.stringify(updatedMyGames));
        } else {
            updatedGames = combinedGames.filter(g => !(g.type === 'favoriteGame' && g.id === game.id));
            const updatedFavoriteGames = updatedGames.filter(g => g.type === 'favoriteGame');
            const updatedIds = updatedFavoriteGames.map(g => g.id).join(',');
            localStorage.setItem('favoriteGames', updatedIds);
            setSavedFavoriteGames(updatedIds);
        }

        console.log("Updated games:", updatedGames);
        setCombinedGames(updatedGames);

        // 更新遊戲順序
        const updatedOrder = updatedGames.map(g => g.id || g.title);
        localStorage.setItem('gameOrder', JSON.stringify(updatedOrder));

        window.location.reload();
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (combinedGames.length === 0) {
        return <div style={{ textAlign: 'center', width: '106px', height: '20px', color: '#fff', fontSize: '14px' }}>No games available</div>;
    }

    return (
        // <div className="slider-container" style={{ width: '94%', margin: '8px' }}>
        <div className="slider-container" style={{ width: '100%', margin: '0 auto' }}>
            <Slider {...settings}>
                {combinedGames.map((game, index) => (
                    // <div key={index} style={{ position: 'relative', display: 'inline-block' }}>
                    <div key={index} style={{
                        position: 'relative',
                    }}>
                        <img
                            src={game.imageUrl}
                            alt={`Slide ${index}`}
                            onClick={() => handleImageClick(game.appStoreLink)}
                            style={{
                                // width: '106px', height: '106px', objectFit: 'cover', margin: spaceWidth, borderRadius: '24px',
                                // width: imageWidth, height: imageWidth, objectFit: 'cover', marginLeft: spaceWidth, marginRight: spaceWidth, borderRadius: '24px',
                                width: imageWidth, height: imageWidth, objectFit: 'cover', marginLeft: spaceWidth, marginRight: spaceWidth, borderRadius: '24px',
                                boxShadow: '4px 4px 6px rgba(0, 0, 0, 0.6)'
                            }}
                        />
                        <img
                            src={game.type === 'myGame' ? removeMyGameImg : removeButtonImg}
                            alt="Remove"
                            style={{
                                position: 'relative',
                                top: - imageWidth,//'-106px',
                                left: imageWidth - 30,// imageWidth - 120,// '80px',
                                width: '30px',
                                height: '30px',
                                cursor: 'pointer',
                                zIndex: '2',
                                marginLeft: spaceWidth,
                                marginRight: spaceWidth,
                            }}
                            onClick={() => removeGame(game)}
                        />
                        <div style={{
                            textAlign: 'center', width: imageWidth, height: '20px',
                            marginLeft: spaceWidth,
                            marginRight: spaceWidth,
                            color: '#fff', fontSize: '14px', position: 'relative', top: '-26px'
                        }}>{game.title}</div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default SliderCombined;