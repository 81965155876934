import React from 'react';
// import ReactDOM from 'react-dom';
import App from './App'; // 引入 App.js
// import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import SimpleSlider from './SimpleSlider';
// import MultipleSlider from './MultipleSlider';
// import FavoriteSlider from './FavoriteSlider';

// ReactDOM.render(
//     <React.StrictMode>
//         <App />
//     </React.StrictMode>,
//     document.getElementById('root')
// );

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <App />,
    {/* </React.StrictMode>, */ }
);