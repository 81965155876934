// import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// import GameInfo from './gameInfo';

const GameDetails = (props) => {
  // 從路由參數中獲取遊戲的 ID
  // const gameId = props.match.params.gameId;
  const { state } = useLocation();

  useEffect(() => {
    console.log(state); // { products: { id: '1', name: 'QQ 產品' } }
  }, []);
  // const StyleSheet = {
  //   width: "100vw",
  //   height: "100vh",
  //   backgroundColor: "#08D9D6",
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   flexDirection: "column"
  // }



  if (!state) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <h1>{state.gameDetail.title}</h1>
      <img src={state.gameDetail.bigImageUrl} alt={state.gameDetail.title} style={{ width: '100%' }} />
      <p>{state.gameDetail.description}</p>
      <a href={state.gameDetail.appStoreLink} target="_blank" rel="noopener noreferrer">
        Go to App Store
      </a>
    </div>
    // );
    // <div style={{ marginBottom: '50px' }}>
    //   <h3 style={{ width: '60%', margin: '0 auto', marginBottom: '6px' }}>Gaming Case Compatible</h3>
    //   <h1>{state.gameDetail.id}</h1>
    //   <GameInfo />
    // </div>
  )
}

export default GameDetails; 